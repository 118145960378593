<template>
  <b-card>
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="idea"
      @remove-item="deleteItem(currentIdea)"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <div class="my-1 mb-2 pl-1">
      <div class="d-flex align-items-center float-left">
        <!-- <a class=" mr-50" @click="$router.go(-1)">
          <feather-icon icon="ChevronLeftIcon" size="20" />
        </a> -->
        <h3 class="m-0 font-weight-600">
          {{ $t("ideas.title") }}
        </h3>
      </div>
      <b-button
        v-if="canCreate"
        v-b-modal.modal-create-ideas
        class="float-right mr-1"
        variant="outline-primary"
      >
        {{ $t("ideas.create") }}
      </b-button>
    </div>

    <!-- Container with data -->
    <div v-if="items && items.length > 0" class="d-flex flex-wrap mt-5 pt-2">
      <!-- Items Loop -->
      <b-card
        v-for="(item, index) in items"
        :key="index"
        no-body
        class="mx-1 mb-3 p-0 card-standard"
        @click="canView ? selectIdea(item) : null"
      >
        <b-card-body class="pb-2">
          <!-- Current community -->
          <b-card-text
            class="d-flex  align-items-center w-100 mb-2 text-secondary"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="38"
                :src="getAvatar(collective.logoURL)"
                :alt="collective.name"
                class="user-avatar mr-1 float-right"
              />
              <span> {{ translateTranslationTable(
                $store.state.locale.currentLocale, collective.name) }} </span>
            </div>
          </b-card-text>

          <!-- Title -->
          <h4 class="mb-1">
            <template v-if="typeof item.name !== 'string'">
              <span
                v-html="
                  translateTranslationTable(
                    $store.state.locale.currentLocale,
                    item.name
                  )
                "
              />
            </template>
            <template v-else>
              <span v-html="item.name" />
            </template>
          </h4>
          <!-- Description object -->
          <template
            v-if="item.description && typeof item.description !== 'string'"
          >
            <span
              v-html="
                translateTranslationTable(
                  $store.state.locale.currentLocale,
                  item.description
                )
              "
              class="content-container"
            />
          </template>
          <!-- Description is string -->
          <template v-else-if="item.description">
            <div class="min-height-3 html-text-ellipsis-2">
              <span v-html="item.description" class="content-container" />
            </div>
          </template>
          <!-- No description -->
          <template v-else>
            <div class="min-height-3"></div>
          </template>
        </b-card-body>
        <b-card-footer style="border-top: 0px !important">
          <!-- Icons info -->
          <span>
            <feather-icon
              size="20"
              :class="item.likedByMember ? 'profile-likes' : 'profile-icon'"
              icon="HeartIcon"
              class="mr-25"
              @click.stop.prevent="toggleLike(item)"
            />
            {{ item.totalLikes || 0 }}
          </span>
          <!-- <span class="ml-2">
              <feather-icon size="20" icon="MessageSquareIcon" class="mr-25" />
              {{ item.comments || 0 }}
            </span> -->
        </b-card-footer>
      </b-card>

      <!-- Buttons prev and next -->
      <button
        class="link-previous btn btn-icon btn-primary btn-previous"
        @click="scrollOnePage(-1)"
      >
        <b-spinner small class="spinner" />
      </button>
      <button
        class="link-next btn btn-icon btn-primary btn-next"
        :class="{ 'btn-next--loading': isLoadingNextPage }"
        @click="scrollOnePage(+1)"
      >
        <b-spinner small class="spinner" />
      </button>
    </div>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="ideasPlaceholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: widgetName }) }}
        </p>
      </b-col>
    </b-row>
    <!-- Load More items -->
    <div v-if="itemsData.meta && items.length < itemsData.meta.total" class="w-100 float-left">
      <b-button
        v-if="!isLoadingNextPage"
        class="center-x my-3"
        variant="outline-primary"
        @click="handleLoadOfNewItems"
      >
        {{ $t("action.load-more") }}
      </b-button>
    </div>
    <!-- Modal idea info-->
    <b-modal
      id="modal-idea-info"
      v-model="modalIdeaShow"
      cancel-variant="outline-secondary"
      :ok-title="$t('form-generic.ok')"
      :cancel-title="$t('form-generic.cancel')"
      centered
      :title="$t('ideas.modal-create-title')"
      @hidden="isEditing = false"
      @ok="handleEditIdea"
    >
      <feather-icon
        v-if="canEdit"
        icon="Trash2Icon"
        size="20"
        class="text-primary edit-icon float-right ml-1"
        role="button"
        @click="isUnshareModalVisible = true"
      />
      <feather-icon
        v-if="canEdit"
        icon="Edit2Icon"
        size="20"
        class="text-primary edit-icon float-right ml-1"
        role="button"
        @click="handleEditingButton"
      />
      <template v-if="isEditing">
        <label for="title">{{ $t("form-create-item.title") }}:</label>
        <b-input v-model="form.name" style="max-width: 300px"/>

      </template>
      <h4 v-else class="mb-1">
        <template v-if="typeof currentIdea.name !== 'string'">
          <span
            v-html="
              translateTranslationTable(
                $store.state.locale.currentLocale,
                currentIdea.name
              )
            "
          />
        </template>
        <template v-else>
          <span v-html="currentIdea.name" />
        </template>
      </h4>
      <template v-if="isEditing">
      <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
        <quill-editor v-model="form.description" />
      </template>
      <div v-else>
        <template v-if="typeof currentIdea.description !== 'string'">
          <span
            v-html="
              translateTranslationTable(
                $store.state.locale.currentLocale,
                currentIdea.description
              )
            "
          />
        </template>
        <template v-else>
          <span v-html="currentIdea.description" />
        </template>
    </div>
    </b-modal>

    <!-- Modal create idea -->
    <b-modal
      id="modal-create-ideas"
      :title="$t('ideas.modal-create-title')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      @ok="handleCreateIdea"
    >
      <b-form>
        <b-form-group>
          <label for="title">{{ $t("form-create-item.title") }}:</label>
          <b-form-input
            id="title"
            v-model="ideaInput.name"
            type="text"
            :state="formError"
          />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="ideaInput.description" />
        </b-form-group>
      </b-form>
    </b-modal>
    <description-addon v-model="isAppInfoModalVisible" :app-id="28" />
  </b-card>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import { quillEditor } from "vue-quill-editor";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BImg,
  BCardText,
  BSpinner,
  BLink,
  BProgress,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BButton,
  BAvatar,
  BModal,
  VBModal,
  BFormTextarea,
} from "bootstrap-vue";
import Teleport from "vue2-teleport";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import IdeasPlaceholder from "@/assets/images/placeholders/light/ideas.svg";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
  name: "IdeasListWidget",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BImg,
    BCardText,
    BSpinner,
    Teleport,
    BLink,
    BProgress,
    BForm,
    quillEditor,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
    BAvatar,
    BFormTextarea,
    DescriptionAddon,
    BModal,
    VBModal,
    UnshareItemModal,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      ideaInput: {},
      currentIdea: {},
      form: {},
      modalIdeaShow: false,
      formError: null,
      scrollEventFired: false,
      isAppInfoModalVisible: false,
      searchInput: "",
      results: [],
      isUnshareModalVisible: false,
      itemData: {},
      isEditing: false,
    };
  },
  computed: {
    // Load data from store
    itemsData() {
      return this.$store.getters.ideas;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    ideasPlaceholder() {
      return IdeasPlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    canEdit() {
      return (
        this.collective.isStaff ||
        this.loggedUser?.key === this.item?.ownedByMember?.key
      );
    },
    canCreate() {
      return checkPermissions(
        "create",
        "idea",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "idea",
        this.loggedMemberRoles,
        this.collective
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    this.updateBreadcrumbs();
    await this.fetchData();
    this.isLoading = false;
    this.checkOpenCreateModal();
  },
  methods: {
    checkOpenCreateModal() {
      if (this.$route.query.openCreateModal === 'true' && this.canCreate) {
        this.$bvModal.show('modal-create-ideas');
      }
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    getAvatar(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "ideas",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          perPage: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (this.items.length < total) {
        this.isLoadingNextPage = true;
        this.lastLoadedPage += 1;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreateIdea(bvModalEvt) {
      if (!this.ideaInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "ideas",
            requestConfig: {
              name: this.ideaInput.name,
              description: this.ideaInput.description,
            },
          },
        });
        this.ideaInput = {};
        this.formError = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async deleteItem(item) {
      try {
        const res = await this.$store.dispatch("unshareIdea", {
          item: {
            itemType: "idea",
            modelKey: item.key,
            key: item.key,
            modelName: "idea",
            tableName: "ideas",
          },
        });
        this.modalIdeaShow = false;
      } catch (err) {
        console.log(err);
      }
    },
    selectIdea(idea) {
      this.currentIdea = idea;
      this.modalIdeaShow = true;
    },
    async toggleLike(item) {
      await this.$store.dispatch("toggleLike", {
        itemType: "ideas",
        morphType: "ideas",
        key: item.key,
      });
      this.fetchData();
    },
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
    translateTranslationTable,
    handleEditingButton() {
      this.form = { ...this.currentIdea };
      this.isEditing = true;
    },
    async handleEditIdea() {
      try {
        await this.$store.dispatch("editItem", {
          item: {
            itemType: 'ideas',
            requestConfig: {
              name: this.form.name,
              description: this.form.description,
              ideaKey: this.form.key,
            },
          },
          method: 'put',
          noSet: 'true',
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        await this.fetchData(true);
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style>
.content-container img {
  width: 100%;
}
.card {
  vertical-align: top !important;
}
.like {
  background-color: white;
}
</style>
